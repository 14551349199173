import React from "react";

type EmptyStateProps = {
  icon?: React.ReactNode;
  title?: string;
  message?: string;
  className?: string;
};

export const EmptyState: React.FC<EmptyStateProps> = ({
  icon,
  message = "There is no data to display at the moment.",
  title = "No Data Available",
  className = "",
}) => {
  return (
    <div className={`flex flex-col items-center justify-center h-full w-full grow  ${className}`}>
      {icon && <div className="mb-1 text-4xl text-gray-500">{icon}</div>}
      <h3 className="text-md font-semibold text-gray-700">{title}</h3>
      {message && <p className="text-gray-500 text-sm">{message}</p>}
    </div>
  );
};

{
  /*<label>
        <input className="peer/showLabel absolute scale-0" type="checkbox" />
        <span className="block max-h-14 max-w-xs overflow-hidden rounded-lg bg-emerald-100 px-4 py-0 text-cyan-800 shadow-lg transition-all duration-700 peer-checked/showLabel:max-h-52">
          <h3 className="flex h-14 cursor-pointer items-center font-bold">
            Expand &amp; Collapse Me
          </h3>
          <p className="mb-2">
            You've crafted a sleek collapsible panel using Tailwind CSS without the need for
            JavaScript. Impressive! 😎
          </p>
        </span>
      </label>*/
}

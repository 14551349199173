import { type loader as RootLoader } from "#app/root";
import { EmptyState } from "#app/components/ui/empty-state.tsx";
import { Button } from "#app/components/ui/button";
import { Badge } from "#app/components/ui/badge";
import { Input } from "#app/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "#app/components/ui/dropdown-menu";
import { cn, getInitials } from "#app/utils/misc.tsx";
import { type IconName } from "@/icon-name";
import { Form, NavLink, useFetcher, useNavigate, useRouteLoaderData } from "@remix-run/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "./ui/icon.tsx";

interface NavItemProps {
  to: string;
  label: string;
  iconName?: IconName;
}

const NavItem: React.FC<NavItemProps> = ({ to, iconName, label }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive, isPending }) =>
        cn(
          isPending && "cursor-none",
          isActive ? "text-primary font-medium" : "text-muted-foreground/70",
          "flex flex-col justify-arround items-center"
        )
      }
    >
      {iconName ? (
        <React.Fragment>
          <Icon name={iconName} stroke="" className="w-6 h-6" />
          <span className="text-sm">{label}</span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <img src="/static/images/logokedi.svg" alt="Logo Kedi" className="w-7 h-7" />
          <span className="text-sm -translate-y-0.5">{label}</span>
        </React.Fragment>
      )}
    </NavLink>
  );
};

const NavigationMenu = ({ className }: { className: string }) => (
  <div className={className}>
    <NavItem to="/" iconName="home" label="Home" />
    <NavItem to="/dashboard" iconName="assessment" label="Dashboard" />
    <NavItem to="/messages" label="Kelas" />
    <NavItem to="/calendar" iconName="date-range" label="Kalendar" />
    <NavItem to="/market" iconName="storefront" label="Market" />
  </div>
);

const Logo = () => (
  <React.Fragment>
    <NavLink to="/" className="hidden sm:inline relative max-w-[208px] -translate-x-2 flex-none">
      <img
        src="/static/images/logomain.svg"
        style={{ width: 208, height: 50 }}
        width={208}
        height={66}
        className="w-auto h-auto"
        alt="Logo Kedi"
      />
    </NavLink>
    <NavLink to="/" className="inline sm:hidden  max-h-[45px] max-w-[150px] -translate-x-0.5">
      <img
        src="/static/images/logotext.svg"
        width="0"
        height="0"
        sizes="100vw"
        className="w-full h-auto"
        alt="Logo Kedi"
      />
    </NavLink>
  </React.Fragment>
);

import { Tabs, TabsContent, TabsList, TabsTrigger } from "#app/components/ui/tabs";
import { Payment, SkeltonList } from "#app/routes/resources+/finance";
import { ClientOnly } from "remix-utils/client-only";
import { ViewportProvider } from "#app/components/client/viewport.client";

import { BellRing, Check } from "lucide-react";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "#app/components/ui/card";
import { Switch } from "#app/components/ui/switch";

const notifications = [
  {
    title: "You have a new message!",
    description: "1 hour ago",
  },
  {
    title: "You have a new message!",
    description: "1 hour ago",
  },
  {
    title: "You have a new message!",
    description: "1 hour ago",
  },
];

type CardProps = React.ComponentProps<typeof Card>;

function CardDemo({ className, ...props }: CardProps) {
  const [unreadNotifications, setUnreadNotifications] = React.useState(null); // Misalnya ada 3 notifikasi belum terbaca

  let notificationContent;
  if (unreadNotifications > 0) {
    notificationContent = `You have ${unreadNotifications} unread notifications.`;
  } else if (unreadNotifications === 0 && unreadNotifications !== null) {
    notificationContent = "All your notifications are read.";
  } else {
    notificationContent = "No notifications at the moment.";
  }
  return (
    <Card className={cn("rounded-sm w-[380px]", className)} {...props}>
      <CardHeader className="p-4">
        <CardTitle>Notifications</CardTitle>
        <CardDescription>{notificationContent}</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4 pl-5 pr-3">
        <div>
          {notifications.map((notification, index) => (
            <div
              key={index}
              className="mb-4 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0"
            >
              <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
              <div className="space-y-1">
                <p className="text-sm font-medium leading-none">{notification.title}</p>
                <p className="text-sm text-muted-foreground">{notification.description}</p>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
      {/*<CardFooter>
        <Button className="w-full">
          <Check /> Mark all as read
        </Button>
      </CardFooter>*/}
    </Card>
  );
}

// Dummy Data Notifikasi (Biasanya dari API)
const dummyNotifications = [
  // {
  //   id: 1,
  //   user: "Jacob Thompson",
  //   message: "has joined your group",
  //   time: "1 day ago",
  //   read: false,
  //   avatar: "/static/images/logokedi.svg",
  // },
  // {
  //   id: 2,
  //   user: "Rizky Hasanuddin",
  //   message: "sent you a private message",
  //   time: "1 day ago",
  //   read: true,
  //   avatar: "/static/images/logokedi.svg",
  // },
  // {
  //   id: 3,
  //   user: "Alice Johnson",
  //   message: "commented on your post",
  //   time: "3 hours ago",
  //   read: false,
  //   avatar: "/static/images/logokedi.svg",
  // },
];

function Notifications() {
  const [notifications, setNotifications] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  // Simulasikan pengambilan data dari API
  React.useEffect(() => {
    setIsLoading(true);
    // Simulate API call
    setTimeout(() => {
      setNotifications(dummyNotifications);
      setIsLoading(false);
    }, 1000);
  }, []);

  // Fungsi untuk menandai semua notifikasi sebagai sudah dibaca
  const markAllAsRead = () => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notif) => ({ ...notif, read: true }))
    );
  };

  // Fungsi untuk menangani klik pada notifikasi (menandai sebagai sudah dibaca)
  const handleMarkAsRead = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notif) => (notif.id === id ? { ...notif, read: true } : notif))
    );
  };

  return (
    <main className="xs:mx-3 bg-white lg:rounded-xl divide-y w-full w-[350px]">
      <div className="flex justify-between px-4 py-3">
        <div className="flex gap-x-1.5">
          <h1 className="font-bold">Notifications</h1>
          {notifications.length > 0 && (
            <Badge variant="secondary" className="rounded-full">
              {notifications.filter((notif) => !notif.read).length}
            </Badge>
          )}
        </div>
        {notifications.length > 0 && (
          <span
            id="mark-all-as-read"
            className="flex items-center gap-x-1 text-sm font-bold text-muted-foreground cursor-pointer hover:text-primary"
            onClick={markAllAsRead}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.49991 0.877045C3.84222 0.877045 0.877075 3.84219 0.877075 7.49988C0.877075 11.1575 3.84222 14.1227 7.49991 14.1227C11.1576 14.1227 14.1227 11.1575 14.1227 7.49988C14.1227 3.84219 11.1576 0.877045 7.49991 0.877045ZM1.82708 7.49988C1.82708 4.36686 4.36689 1.82704 7.49991 1.82704C10.6329 1.82704 13.1727 4.36686 13.1727 7.49988C13.1727 10.6329 10.6329 13.1727 7.49991 13.1727C4.36689 13.1727 1.82708 10.6329 1.82708 7.49988ZM10.1589 5.53774C10.3178 5.31191 10.2636 5.00001 10.0378 4.84109C9.81194 4.68217 9.50004 4.73642 9.34112 4.96225L6.51977 8.97154L5.35681 7.78706C5.16334 7.59002 4.84677 7.58711 4.64973 7.78058C4.45268 7.97404 4.44978 8.29061 4.64325 8.48765L6.22658 10.1003C6.33054 10.2062 6.47617 10.2604 6.62407 10.2483C6.77197 10.2363 6.90686 10.1591 6.99226 10.0377L10.1589 5.53774Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
            <span>Mark all as read</span>
          </span>
        )}
      </div>

      {isLoading ? (
        <div
          role="status"
          className="w-[330px] p-3 space-y-4  divide-y divide-gray-200 rounded  animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
        >
          <div className="flex items-center justify-between">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      ) : notifications?.length > 0 ? (
        notifications.map((notif) => (
          <div
            key={notif.id}
            className={`flex justify-between p-3 ${notif.read ? "bg-background" : "notification-unread"}`}
          >
            <Avatar className="h-8 w-8 rounded-md">
              <AvatarImage
                className="rounded-md"
                src={notif.avatar ? notif.avatar : "/static/images/logokedi.svg"}
                alt={notif?.user}
              />
              <AvatarFallback className="rounded-md">{getInitials(notif.user)}</AvatarFallback>
            </Avatar>
            <div className="ml-2 text-xs flex-auto">
              <a
                href="#"
                className={`font-bold ${notif.read ? "" : ""}`}
                onClick={() => handleMarkAsRead(notif.id)}
              >
                {notif.user}
              </a>
              <span className="text-muted-foreground ml-2 text-xs">{notif.message}</span>
              <p className="text-muted-foreground mt-1 text-xs">{notif.time}</p>
            </div>
          </div>
        ))
      ) : (
        <div className="flex items-center justify-center text-gray-600  h-[95%]">
          <EmptyState icon={<Icon name="notifications" />} title="No notification display" />
        </div>
      )}
    </main>
  );
}

const NotifIconX = () => {
  const [value, setValue] = React.useState("contacts");
  const { user } = useRouteLoaderData<typeof RootLoader>("root") || {};
  const notifMessage: [] | null = null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="rounded-lg relative outline-none h-9 w-9">
        <ButtonDemo />
        {/*<Button size="icon" variant="ghost" className="[&_svg]:size-5 rounded-md">
          {notifMessage?.filter((d: any) => d.publisher !== user?.uuid).length > 0 && (
            <span className="absolute py-0.5 px-1.5 bg-green-400 rounded-full -top-1 right-1 border-2 border-white text-sm font-medium">
              {notifMessage?.filter((d) => d.publisher !== user?.uuid).length}
            </span>
          )}
          <BellRing />
        </Button>*/}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="border-none mr-3">
        <Notifications />
        {/*<CardDemo />*/}
        {/*<Tabs value={value} onValueChange={setValue}>
          <TabsList className="">
            <TabsTrigger value="class">Notifikasi</TabsTrigger>
            <TabsTrigger value="contacts">Status Transaksi</TabsTrigger>
          </TabsList>
          <TabsContent
            forceMount
            hidden={value !== "class"}
            value="class"
            className="h-[60vh] overflow-y-auto px-1.5 space-y-2 pt-1.5 border rounded-lg mx-2 mb-2 mt-3 shadow-sm"
          >
            <div className="grid h-full place-content-center px-4">
              <div className="text-center">
                <h1 className="text-6xl font-black text-gray-200">Progress</h1>

                <p className="mt-4 text-gray-500">this menu is on proggres</p>
              </div>
            </div>
          </TabsContent>
          <TabsContent
            forceMount
            hidden={value !== "contacts"}
            value="contacts"
            className="px-1.5 pb-4"
          >
            <ClientOnly fallback={<SkeltonList />}>
              {() => (
                <ViewportProvider>
                  <Payment isNotif={true} />
                </ViewportProvider>
              )}
            </ClientOnly>
          </TabsContent>
        </Tabs>*/}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const NotifIcon = () => {
  const [value, setValue] = React.useState("contacts");
  const { user } = useRouteLoaderData<typeof RootLoader>("root") || {};
  const notifMessage: [] | null = null;
  const [count, setCount] = useState(4);

  const handleClick = () => {
    setCount(0);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="rounded-lg relative outline-none h-9 w-9" asChild>
        <Button
          variant="ghost"
          size="icon"
          className="relative [&_svg]:size-5 h-9 w-9"
          onClick={handleClick}
          aria-label="Notifications"
        >
          <Bell strokeWidth={2} aria-hidden="true" />
          {count > 0 && (
            <Badge className="absolute -top-1.5 -right-3 min-w-5 -translate-x-1/2 px-1">
              {count > 99 ? "99+" : count}
            </Badge>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="border-none mr-3">
        <Notifications />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "#app/components/ui/collapsible";
export const Navbar = () => {
  return (
    <>
      <header className="sm:block hidden border-grid sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="container-wrapper">
          <div className="flex items-center gap-x-3 justify-between [@media(min-width:900px)]:container [@media(min-width:900px)]:mx-auto px-2.5 w-full gap-3 pb-0.5 [@media(min-width:900px)]:h-[60px] h-14">
            <Logo />
            <NavigationMenu className="hidden [@media(min-width:900px)]:flex items-center justify-center gap-x-3 lg:gap-x-5 bg-white/50 rounded-lg px-3 py-0.5" />
            <div className="flex items-center justify-end gap-x-1.5 sm:gap-x-4 md:gap-x-3">
              <CommandMenu /> <PopoverDemo /> <UserNav />
            </div>
          </div>
        </div>
      </header>
      <header className="sm:hidden block border-grid sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/80">
        <ButtonNav />
      </header>
    </>
  );
};

function ButtonNav() {
  const [open, setOpen] = React.useState<boolean>(false);
  const is_container = React.useRef(null);
  const is_trigger = React.useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (is_container.current && !is_container.current.contains(event.target as Node)) {
      if (is_trigger.current && !is_trigger.current.contains(event.target as Node)) {
        setOpen(false); // Tutup dropdown jika klik di luar
      }
    }
  };

  React.useEffect(() => {
    if (open) {
      // Tambahkan listener hanya saat dropdown terbuka
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Hapus listener saat dropdown tertutup
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Bersihkan listener saat komponen unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);
  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="flex items-center gap-x-3 justify-between [@media(min-width:900px)]:container [@media(min-width:900px)]:mx-auto px-2.5 w-full gap-3 pb-0.5 [@media(min-width:900px)]:h-[60px] h-14">
        <Logo />
        <NavigationMenu className="hidden [@media(min-width:900px)]:flex items-center justify-center gap-x-3 lg:gap-x-5 bg-white/50 rounded-lg px-3 py-0.5" />
        <div className="flex items-center justify-end gap-x-1.5 sm:gap-x-4 md:gap-x-3">
          <CommandMenu /> <PopoverDemo />
          <CollapsibleTrigger asChild>
            <Button
              className="group sm:hidden border-none bg-transparent"
              variant="ghost"
              size="icon"
              ref={is_trigger}
              onClick={() => setOpen((prevState) => !prevState)}
              aria-expanded={open}
              aria-label={open ? "Close menu" : "Open menu"}
            >
              <svg
                className="pointer-events-none"
                width={16}
                height={16}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 12L20 12"
                  className="origin-center -translate-y-[7px] transition-all duration-300 [transition-timing-function:cubic-bezier(.5,.85,.25,1.1)] group-aria-expanded:translate-x-0 group-aria-expanded:translate-y-0 group-aria-expanded:rotate-[315deg]"
                />
                <path
                  d="M4 12H20"
                  className="origin-center transition-all duration-300 [transition-timing-function:cubic-bezier(.5,.85,.25,1.8)] group-aria-expanded:rotate-45"
                />
                <path
                  d="M4 12H20"
                  className="origin-center translate-y-[7px] transition-all duration-300 [transition-timing-function:cubic-bezier(.5,.85,.25,1.1)] group-aria-expanded:translate-y-0 group-aria-expanded:rotate-[135deg]"
                />
              </svg>
            </Button>
          </CollapsibleTrigger>
          <UserNav />
        </div>
      </div>
      <CollapsibleContent ref={is_container}>
        <nav className="flex-shrink-0 bg-transparent border-y">
          <div className="sm:hidden" id="mobile-menu">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {[...mainLinks].map((navItem, index) => (
                <NavLink
                  key={index}
                  to={navItem.href}
                  onClick={() => setOpen(false)}
                  className={({ isActive, isPending }) =>
                    cn(
                      "flex items-center gap-1.5 px-3 py-2 rounded-md text-base font-medium",
                      isActive ? "bg-primary text-white" : ""
                    )
                  }
                >
                  <navItem.icon />
                  <span>{navItem.title}</span>
                </NavLink>
              ))}
            </div>
          </div>
        </nav>
      </CollapsibleContent>
    </Collapsible>
  );
}

export const NavbarBottom = () => {
  return (
    <>
      <div className="h-[56px]" />
      <nav className="[@media(min-width:900px)]:hidden border-t fixed bottom-0 z-50 w-full border-b bg-background backdrop-blur ">
        <NavigationMenu className="grid h-full max-w-lg grid-cols-5 mx-auto font-medium pt-1.5" />
      </nav>
    </>
  );
};

import { Avatar, AvatarFallback, AvatarImage } from "#app/components/ui/avatar";

function UserNav() {
  const { user } = useRouteLoaderData<typeof RootLoader>("root") || {};

  const { i18n } = useTranslation();
  const fetcher = useFetcher();

  const onValueChange = (lang: string) => {
    i18n.changeLanguage(lang);
    fetcher.submit(null, {
      method: "POST",
      action: `/settings/change-language/${lang}`,
    });
  };

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="[@media(min-width:900px)]:ml-0 ml-1.5 border-none h-9 w-9"
        >
          <Avatar className="h-8 w-8 md:h-9 md:w-9 rounded-md">
            <AvatarImage
              className="rounded-md object-cover"
              src={user?.photo ? user?.photo : "/static/images/logokedi.svg"}
              alt={user?.fullName}
            />
            <AvatarFallback className="h-8 w-8 md:h-9 md:w-9 rounded-md rounded-md">
              {getInitials(user?.fullName)}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal flex items-center gap-x-2">
          <Avatar className="h-8 w-8">
            <AvatarImage
              className="object-cover"
              src={user?.photo ? user?.photo : "/static/images/logokedi.svg"}
              alt={user?.fullName}
            />
            <AvatarFallback>{getInitials(user?.fullName)}</AvatarFallback>
          </Avatar>
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{user?.fullName}</p>
            <p className="text-xs leading-none text-muted-foreground max-w-40 truncate">
              {user?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <Icon name="language" size="md" className="sm:flex hidden text-gray-600">
                Bahasa
              </Icon>
            </DropdownMenuSubTrigger>
            <DropdownMenuSubContent>
              <DropdownMenuRadioGroup value={i18n.language} onValueChange={onValueChange}>
                <DropdownMenuRadioItem value="id">Indonesia</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="en">English</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuSubContent>
          </DropdownMenuSub>
          <DropdownMenuItem asChild>
            <Form
              method="get"
              action={`/profile/${user?.id}`}
              className="flex w-full items-center gap-1.5 rounded-md p-2 text-sm hover:bg-purple-100"
              role="menuitem"
            >
              <button type="submit" className="flex w-full items-center gap-1.5 rounded-md text-sm">
                <Icon name="avatar" size="md">
                  Profile
                </Icon>
              </button>
            </Form>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Form
              method="get"
              action={`/settings/profile`}
              className="flex w-full items-center gap-1.5 rounded-md p-2 text-sm hover:bg-purple-100"
              role="menuitem"
            >
              <button type="submit" className="flex w-full items-center gap-1.5 rounded-md text-sm">
                <Icon name="settings" size="md">
                  Settings
                </Icon>
              </button>
            </Form>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Form
            method="post"
            action={`/logout`}
            className="flex w-full items-center gap-1.5 rounded-md p-2 text-sm hover:bg-purple-100"
            role="menuitem"
          >
            <button type="submit" className="flex w-full items-center gap-1.5 rounded-md text-sm">
              <Icon name="logout" size="md">
                Log Out
              </Icon>
            </button>
          </Form>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandShortcut,
  CommandList,
  CommandSeparator,
} from "#app/components/ui/command";
import { type DialogProps } from "@radix-ui/react-dialog";

import {
  Search,
  Settings,
  Contact,
  Command as CommandIcon,
  Book,
  Circle,
  CreditCard,
  Heart,
  Star,
  Bookmark,
  Sun,
  List,
  Calculator,
  Timer,
  House,
  SquareKanban,
  MessageCircleMore,
  CalendarDays,
  Store,
} from "lucide-react";

const mainLinks: {
  title: string;
  href: string;
  description: string;
  icon: SVGSVGElement;
}[] = [
  {
    title: "Home",
    href: "/",
    description: "Beranda",
    icon: House,
  },
  {
    title: "Dashboard",
    href: "/dashboard",
    description: "Dashboard",
    icon: SquareKanban,
  },
  {
    title: "Kelas",
    href: "/messages",
    description: "kelas chat, group chat, personal chat",
    icon: MessageCircleMore,
  },
  {
    title: "Calendar",
    href: "/calendar",
    description: "Calendar",
    icon: CalendarDays,
  },
  {
    title: "Market",
    href: "/market",
    description: "Marketplace",
    icon: Store,
  },
];
const muslimLinks: {
  title: string;
  href: string;
  description: string;
  icon: SVGSVGElement;
}[] = [
  {
    title: "Search Class, Feed, and Webinar",
    href: "/search?tab=class",
    description: "Cari kelas yang terdaftar di kelas digital",
    icon: Search,
  },
  {
    title: "Search User Kelas Digital",
    href: "/search",
    description: "Cari orang yang terdaftar di kelas digital",
    icon: Search,
  },
  {
    title: "Settings Profile",
    href: "/settings/profile",
    description: "Setting account",
    icon: Settings,
  },
  {
    title: "Contacts",
    href: "/dashboard/contact",
    description: "Setting account",
    icon: Contact,
  },
  {
    title: "Kedi Pay",
    href: "/dashboard/finance",
    description: "kedipay",
    icon: CreditCard,
  },
];

function CommandMenu({ ...props }: DialogProps) {
  const navigate = useNavigate();
  const isDesktopRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const width = window.innerWidth;
    const is_desktop = width > 900;
    isDesktopRef.current = is_desktop;
    const down = (e: KeyboardEvent) => {
      if ((e.key === "k" && (e.metaKey || e.ctrlKey)) || e.key === "/") {
        if (
          (e.target instanceof HTMLElement && e.target.isContentEditable) ||
          e.target instanceof HTMLInputElement ||
          e.target instanceof HTMLTextAreaElement ||
          e.target instanceof HTMLSelectElement
        ) {
          return;
        }

        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  const runCommand = React.useCallback((command: () => unknown) => {
    setOpen(false);
    command();
  }, []);

  return (
    <>
      <Button
        variant="outline"
        className={cn(
          "sm:flex hidden relative h-9 w-full justify-start rounded-md bg-muted/50 font-normal text-muted-foreground shadow-none sm:pr-12 md:w-40"
        )}
        onClick={() => setOpen(true)}
        {...props}
      >
        <span className="hidden lg:inline-flex">Search ...</span>
        <span className="inline-flex lg:hidden">Search ...</span>
        <kbd className="pointer-events-none absolute right-[0.3rem] top-[0.45rem] hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
          <span className="text-sm pt-0.5">⌘</span>K
        </kbd>
      </Button>
      <Button
        variant="ghost"
        size="icon"
        className="sm:hidden [&_svg]:size-5 h-9 w-9"
        onClick={() => setOpen(true)}
      >
        <Search strokeWidth={2} />
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Search Menu Kelas Digital..." />
        <CommandList className="max-h-[calc(100vh-200px)] h-full">
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandSeparator />
          <CommandGroup heading="Suggestions">
            {[...muslimLinks].map((navItem, index) => (
              <CommandItem
                key={index}
                value={index}
                className="flex items-center gap-1.5"
                onSelect={() => {
                  runCommand(() => navigate(navItem.href as string));
                }}
              >
                <navItem.icon />
                <span>{navItem.title}</span>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  );
}

import { Bell } from "lucide-react";
import { useState } from "react";

function ButtonDemo() {
  const [count, setCount] = useState(4);

  const handleClick = () => {
    setCount(0);
  };

  return (
    <Button
      variant="outline"
      size="icon"
      className="relative [&_svg]:size-5 h-9 w-9"
      onClick={handleClick}
      aria-label="Notifications"
    >
      <Bell strokeWidth={2} aria-hidden="true" />
      {count > 0 && (
        <Badge className="absolute -top-2 left-full min-w-5 -translate-x-1/2 px-1">
          {count > 99 ? "99+" : count}
        </Badge>
      )}
    </Button>
  );
}

import { Popover, PopoverContent, PopoverTrigger } from "#app/components/ui/popover";

function Dot({ className }: { className?: string }) {
  return (
    <svg
      width="6"
      height="6"
      fill="currentColor"
      viewBox="0 0 6 6"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden="true"
    >
      <circle cx="3" cy="3" r="3" />
    </svg>
  );
}

function PopoverDemo() {
  const [notifications, setNotifications] = useState([]);
  const unreadCount = notifications.filter((n) => n.unread).length;
  const loadNotifications = () => {
    const storedNotifications = JSON.parse(localStorage.getItem("notifications")) || [];
    setNotifications(storedNotifications);
    const notificationBadge = document.getElementById("notification-badge");
    if (notificationBadge) {
      notificationBadge.style.display = "none";
    }
  };
  const handleMarkAllAsRead = () => {
    const data = notifications.map((notification) => ({
      ...notification,
      unread: false,
    }));
    setNotifications(data);

    localStorage.setItem("notifications", JSON.stringify(data));
  };

  const handleNotificationClick = (id: number) => {
    const data = notifications.map((notification) =>
      notification.id === id ? { ...notification, unread: false } : notification
    );
    setNotifications(data);

    localStorage.setItem("notifications", JSON.stringify(data));
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          onClick={loadNotifications}
          size="icon"
          variant="ghost"
          className="h-9 w-9 [&_svg]:size-5 relative"
          aria-label="Open notifications"
        >
          <Bell size={16} strokeWidth={2} aria-hidden="true" />
          {unreadCount > 0 && (
            <Badge className="absolute -top-1 left-[28px] min-w-5 -translate-x-1/2 px-1">
              {unreadCount > 99 ? "99+" : unreadCount}
            </Badge>
          )}

          <Badge
            id="notification-badge"
            style={{ display: "none" }}
            className="absolute -top-1 left-[28px] min-w-5 -translate-x-1/2 px-1"
          >
            0
          </Badge>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80 p-1 mr-2">
        <div className="flex items-baseline justify-between gap-4 px-3 py-2">
          <div className="text-sm font-semibold">Notifications</div>
          {unreadCount > 0 && (
            <button className="text-xs font-medium hover:underline" onClick={handleMarkAllAsRead}>
              Mark all as read
            </button>
          )}
        </div>
        <div
          role="separator"
          aria-orientation="horizontal"
          className="-mx-1 my-1 h-px bg-border"
        ></div>
        <div className="max-h-[50vh] overflow-y-auto">
          {notifications?.length > 0 ? (
            notifications?.reverse().map((notification, index) => (
              <div
                key={index}
                className="rounded-md px-3 py-2 text-sm transition-colors hover:bg-accent"
              >
                <div className="relative flex items-start gap-3 pe-3">
                  <Avatar className="flex-shrink-0 h-8 w-8 relative flex justify-center items-center border border-primary shadow">
                    <AvatarImage
                      className="rounded-md"
                      src={notification.image}
                      alt={notification?.user}
                    />
                    <AvatarFallback className="bg-secondary text-primary rounded-md font-medium">
                      {getInitials(notification.user)}
                    </AvatarFallback>
                  </Avatar>
                  <div className="flex-1 space-y-1">
                    <button
                      className="text-left text-foreground/80 after:absolute after:inset-0"
                      onClick={() => handleNotificationClick(notification.id)}
                    >
                      <span className="font-medium text-foreground hover:underline">
                        {notification.user}
                      </span>{" "}
                      {notification.action}{" "}
                      <span className="font-medium text-foreground hover:underline">
                        {notification.target}
                      </span>
                      .
                    </button>
                    <div className="text-xs text-muted-foreground">{notification.timestamp}</div>
                  </div>
                  {notification.unread && (
                    <div className="absolute end-0 self-center">
                      <Dot />
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center text-gray-600 h-[95%] py-3">
              <EmptyState icon={<Icon name="notifications" />} title="No notification display" />
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}
